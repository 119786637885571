<template>
  <i-o-table
    ref="ioTable"
    :columns="columns"
    :rows="labels"
    view-only="True"
    :data-set-name="dataSetName"
    description="Label data"
    @delete-rows="deleteLabels"
    @check-rows="checkLabels"
    @add-default-row="addDefaultLabel"
    @add-row="addLabel"
    @add-rows="addLabels"
  />
</template>

<script setup>
import {ref, defineProps, onBeforeMount, defineExpose, defineEmits} from "vue";
import {LabelDataEntry, LabelDataSetPostPut} from "@/types";
import IOTable from "@/components/base-components/IOTable.vue";
import {postData} from "@/api";

const props = defineProps({
  labels: {
    type: Object,
    required: true,
  },
  dataSetName: {
    type: String,
    required: true,
  }
});

const emit = defineEmits(['updateErrors']);

const ioTable = ref();

const labels = ref(null);
const dataSetName = ref(props.dataSetName);

const columns = ref([
  {field: 'postal_code', header: 'postcode', options: null},
  {field: 'house_number', header: 'huisnummer', options: null},
  {field: 'house_letter', header: 'huisletter', options: null},
  {field: 'house_number_annex', header: 'huisnr annex', options: null},
  {field: 'applicable', header: 'succesvol', options: null, set_if_empty: '0'},
  {field: 'costs', header: 'kosten', options: null, set_if_empty: '0'},
  {field: 'energy_savings', header: 'energie besparing', options: null, set_if_empty: '0'},
]);
const errorMessages = ref(null);
const errorIndices = ref([]);

onBeforeMount(() => {
  labels.value = props.labels;
});

const deleteLabels = (deleteIndices) => {
  for (let index of deleteIndices) {
    labels.value.splice(index, 1);
  }
};

const checkLabels = async (successCallback) => {
  setTimeout(async function () { // wait 1 ms to perform check after update of datatable values
    errorMessages.value = [];
    errorIndices.value = [];
    postalCodeHouseLetterAnnexToUpperCase();
    checkFormat();
    checkDuplicates();
    if (errorMessages.value.length === 0) {
      await validateLabelsBackend();
    }

    // Remove duplicates error indices
    errorIndices.value = Array.from(new Set(errorIndices.value));

    // Select and move to top of datatable
    if (ioTable.value) {
      ioTable.value.selectErrorRows(errorIndices.value);
    }
    // Set error
    let errorDisplayMessage = null;
    if (errorMessages.value.length > 0) {
      errorDisplayMessage = "Problemen gevonden: "
      errorMessages.value.forEach((message, index) => {
        errorDisplayMessage += `'${message}'`;
        if (index < errorMessages.value.length - 1) {
          errorDisplayMessage += `, `;
        }
      })
    }
    emit("updateErrors", errorIndices.value.length, errorDisplayMessage);

    // Don't call the success callback if there's a validation error
    if (errorDisplayMessage) {
      return;
    }

    // add/update label data set
    if (successCallback && typeof successCallback === 'function') {
      successCallback(errorIndices.value.length);
    }
  }, 1);
};

const validateLabelsBackend = async () => {
  const labelDataSet = new LabelDataSetPostPut(
      "validation_label_data_set",
      labels.value,
      "0" // not used in validation for now
  )
  const resp = await postData("label_data_set/validate", labelDataSet);

  if (resp == null) {
    errorMessages.value.push("Er is een fout opgetreden");
    errorIndices.value.push(-1);
    return;
  }

  const validateData = await resp.json();
  if (validateData.message !== "") {
    errorMessages.value.push(validateData.message)
    validateData.error_label_data_entry_indices.forEach((index) => {
      errorIndices.value.push(index);
    });
  }
};

const postalCodeHouseLetterAnnexToUpperCase = () => {
  labels.value.forEach((label) => {
    if (label['postal_code']) {
      label['postal_code'] = label['postal_code'].toString().toUpperCase();
    }
    if (label['house_letter']) {
      label['house_letter'] = label['house_letter'].toString().toUpperCase();
    }
    if (label['house_number_annex']) {
      label['house_number_annex'] = label['house_number_annex'].toString().toUpperCase();
    }
  });
};

const checkFormat = () => {
  let emptyPostalCodeOrHouseNrFound = false;
  let wrongFormat = false;
  let wrongHouseLetter = false;
  labels.value.forEach((label, index) => { // all address strings
    if (label['postal_code'] === "" || label['house_number'] === "" || label['postal_code'] === undefined || label['house_number'] === undefined) {
      emptyPostalCodeOrHouseNrFound = true;
      errorIndices.value.push(index);
    }
    const pattern = /^[a-zA-Z]$/;
    if (label['house_letter'] && !pattern.test(label['house_letter'])) {
      wrongHouseLetter = true;
      errorIndices.value.push(index);
    }
    if (!Number.isInteger(Number(label['house_number'])) || !Number.isInteger(Number(label['applicable'])) ||
        isNaN(label['costs']) || isNaN(label['energy_savings']) || label['house_number'].length === 0 ||
        label['applicable'].length === 0 || label['costs'].length === 0 || label['energy_savings'].length === 0) {
      wrongFormat = true;
      errorIndices.value.push(index);
    }
  });

  if (emptyPostalCodeOrHouseNrFound) {
    errorMessages.value.push("lege postcode en/of huisnummer");
  }
  if (wrongHouseLetter) {
    errorMessages.value.push("huisletter niet één letter");
  }
  if (wrongFormat) {
    errorMessages.value.push(
        "verkeerd format (huisnummer, succesvol: geheel getal; kosten, energie besparing: getal)"
    );
  }
};

const checkDuplicates = () => {
  const uniqueAddresses = {};
  const duplicateIndices = [];
  labels.value.forEach((label, index) => { // all address strings
    let addressString = `${label['postal_code']}_${label['house_number']}`;
    if (label['house_letter']) {
      addressString += `_${label['house_letter']}`;
    }
    if (label['house_number_annex']) {
      addressString += `_${label['house_number_annex']}`;
    }

    if (addressString in uniqueAddresses) {
      duplicateIndices.push(index);
    } else {
      uniqueAddresses[addressString] = index;
    }
  });

  if (duplicateIndices.length > 0) {
    errorIndices.value.push(...duplicateIndices);
    errorMessages.value.push("dubbele adressen");
  }
};

const addDefaultLabel = (index) => {
  labels.value.unshift(
      new LabelDataEntry(index, "1234AA", "1", "", "", 1, 0, 0)
  );
};

const addLabel = (index, valuesDict) => {
  labels.value.push(
      new LabelDataEntry(index, valuesDict['postal_code'], valuesDict["house_number"],
          valuesDict["house_letter"], valuesDict["house_number_annex"], valuesDict["applicable"],
          valuesDict["costs"], valuesDict["energy_savings"])
  );
};

const addLabels = (index, labelDataSet) => {
  let entryIndex = index;
  const labelDataEntries = labelDataSet.map(valuesDict => new LabelDataEntry(
      entryIndex++,
      valuesDict['postal_code'],
      valuesDict["house_number"],
      valuesDict["house_letter"],
      valuesDict["house_number_annex"],
      valuesDict["applicable"],
      valuesDict["costs"],
      valuesDict["energy_savings"]));
  labels.value.push(...labelDataEntries);
};

defineExpose({
  checkLabels
});

</script>

<style lang="scss" scoped>

</style>