<template>
  <DataTable
    v-model:selection="selectedRow"
    v-model:multiSortMeta="multiSortOptions"
    :value="props.rows"
    sort-mode="multiple"
    data-key="id"
    class="p-datatable-sm"
    paginator
    :loading="props.loading"
    :rows="props.pageSize"
    :rows-per-page-options="[5, 10, 20, 50, 100]"
    @row-select="onSelectRow"
  >
    <template #empty>
      Geen data gevonden
    </template>
    <template #loading><loading-indicator /></template>
    <Column
      v-if="selectableRow"
      selection-mode="single"
      header-style="width: 3em"
      style="max-width: 50px"
    />
    <Column v-if="editableRow || deletableRow || customActions" header="Actie" style="max-width: 120px">
      <template #body="slotProps">
        <i
          v-if="deletableRow && isEditable(slotProps.data)"
          v-tooltip="'Verwijderen'"
          class="pi pi-trash px-2"
          @click="confirmDeleteRow($event, slotProps.data)"
        />
        <i
          v-if="editableRow && isEditable(slotProps.data)"
          v-tooltip="'Wijzigen'"
          class="pi pi-pencil px-2"
          @click="editRow(slotProps.data.id)"
        />
        <span v-for="customAction in customActions" :key="customAction.id">
          <i
            v-if="customAction.visible(slotProps.data.id)"
            v-tooltip="customAction.tooltip"
            :class="'pi ' + customAction.icon + ' px-2'"
            @click="customAction.handler(slotProps.data.id)"
          />
        </span>
      </template>
    </Column>
    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :options="col.options"
      sortable
    >
      <template v-if="col.format" #body="slotProps">
        {{ reformat(slotProps.data[col.field], col.format) }}
      </template>
    </Column>
    <Column v-if="badges" :header="badges.name">
      <template #body="slotProps">
        <span :class="'row-badge row-' + badges.values[slotProps.data.id]">
          {{ badges.display_values[slotProps.data.id] }}
        </span>
      </template>
    </Column>
  </DataTable>
  <Dialog v-model:visible="showDeleteRowDialog" :style="{width: '450px'}" header="Bevestig verwijdering" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="deleteRowId">Weet u zeker dat u '{{ rowToDelete.name }}' van gebruiker '{{ rowToDelete.user_full_name }}' wilt verwijderen?</span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" text @click="showDeleteRowDialog = false" />
      <Button label="Ja" icon="pi pi-check" text class="p-button-danger" @click="deleteRow" />
    </template>
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import {dateTimeToString} from "@/helpers/functions";
import {DataTableSortOption} from "@/types";
import LoadingIndicator from "@/components/base-components/LoadingIndicator.vue";

const props = defineProps({
  rows: {
    type: Object,
    required: true,
  },
  columns: {
    type: Object,
    required: true,
  },
  pageSize: {
    type: Number,
    required: false,
    default: 5
  },
  editableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  deletableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  selectableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  selectedRow: {
    type: Object,
    required: false,
    default: null
  },
  badges: {
    type: Object,
    required: false,
    default: null
  },
  sortOption: {
    type: DataTableSortOption,
    required: false,
    default: null
  },
  customActions: {
    type: Object,
    required: false,
    default: null
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  }
});

const loading = ref(false);
const rows = ref(null);
const columns = ref(null);
const editableRow = ref(null);
const selectableRow = ref(null);
const selectedRow = ref(null);
const customActions = ref(null);
const multiSortOptions = ref([]);
const emit = defineEmits(["select", "edit", "delete", "update:selectedRow"]);
const showDeleteRowDialog = ref(false);
const deleteRowId = ref(-9999);
const rowToDelete = ref(null);

onMounted(() => {
  rows.value = props.rows;
  columns.value = props.columns;
  editableRow.value = props.editableRow;
  selectableRow.value = props.selectableRow;
  selectedRow.value = props.selectedRow;
  customActions.value = props.customActions;
  loading.value = props.loading;
  setPresortOptions();
});

const setPresortOptions = () => {
  if (props.sortOption != null) {
    // According to the PrimeVue docs (https://primevue.org/datatable/#pre_sort), we need an array
    // of DataTableSortMeta objects to support multisort, but apparently an array of objects with field and
    // order suffices. The DataTableSortMeta class isn't available in our current frontend environment. Might have got
    // to do with the version of PrimeVue we are using.
    multiSortOptions.value = [{field: props.sortOption.field, order: props.sortOption.order}];
  }
};

const isEditable = (data) => {
  if (!("is_editable" in data)) {
     return true;
  }
  return data["is_editable"];
};

const onSelectRow = (event) => {
  emit("update:selectedRow", selectedRow.value);
  emit("select", event.data);
};

const editRow = (row_id) => {
  emit("edit", row_id);
};

const confirmDeleteRow = (event, data) => {
  rowToDelete.value = data;
  setDeleteRowId(data.id);
  showDeleteRowDialog.value = true;
};

const deleteRow = () => {
  emit("delete", deleteRowId.value);
  resetDeleteRowId();
  showDeleteRowDialog.value = false;
};

const resetDeleteRowId = () => {
  setDeleteRowId(-9999);
};

const setDeleteRowId = (row_id) => {
  deleteRowId.value = row_id;
};

const reformat = (value, format) => {
  if (format === "datetime") {
    return dateTimeToString(value);
  } else if (format === "fraction_is_percentage") {
    return value != null ? `${Math.floor(value * 100)}%` : "Onbekend"
  } else {
    return value;
  }
};

</script>

<style scoped>
.ui-datatable-scrollable-header-box table th, .ui-datatable-scrollable-footer-box table th, .ui-datatable-scrollable-body table td {
  width: 20% !important;
}
</style>