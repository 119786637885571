<template>
  <div class="pb-2">
    <Card>
      <template #title>
        Contingent: definieer en start berekening
        <help-sidebar help-reference="Contingent: definieer en start berekening" />
      </template>
      <template #content>
        <label for="naam">Naam</label>
        <InputText
          id="naam"
          v-model="predictionName"
          type="text"
          :invalid="predictionName === ''"
          class="w-full"
        />
        <h4>Selecteer een getraind model</h4>
        <view-edit-select-table
          v-model:selected-row="selectedTrainingResult"
          :rows="trainingResults"
          :columns="trainedModelColumns"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :badges="trainingResultsQualities"
          :custom-actions="trainedModelActions"
          :selectable-row="true"
          :loading="loadingTrainedModels || allModels === null || allPredictionsModels === null"
          @select="onSelectTrainedModel"
        />
        <h4>Selecteer gebied waarvoor het contingent bepaald moet worden</h4>
        <div>
          <div class="field-radiobutton">
            <RadioButton v-model="selectedArea" input-id="city" name="city" value="city" />
            <label for="city" class="mr-4">Stad</label>
            <city-select
              v-if="selectedArea === 'city'"
              v-model:selected_city="selectedCity"
            />
          </div>
          <!--          <div class="field-radiobutton">-->
          <!--            <RadioButton v-model="selected_area" input-id="province" name="province" value="province" disabled="true" />-->
          <!--            <label for="province">Provincie</label>-->
          <!--            <province-select-->
          <!--              v-if="selected_area === 'province'"-->
          <!--              v-model:selected_province="selected_province"-->
          <!--              class="ml-2"-->
          <!--              @update:selectedProvince="(province) => {selected_province.value = province;}"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="field-radiobutton">-->
          <!--            <RadioButton v-model="selectedArea" input-id="country" name="country" value="country" />-->
          <!--            <label for="country">Heel Nederland</label>-->
          <!--          </div>-->
        </div>
        <h4>Selecteer predictie model</h4>
        <div class="select-model">
          <label class="ml-2">Predictie model:</label>
          <Dropdown
            v-model="selectedPredictionModelName"
            :options="predictionModels"
            option-label="display_name"
            option-value="pipeline_name"
            :invalid="selectedPredictionModelName === null"
            class="my-1"
            @change="onSelectPredictionModel"
          />
          <label class="ml-3" :class="{'text-red-500': noPredctionModelsFound}">{{ predictionModelMessage }}</label>
        </div>
        <div v-if="hasRole('admin')">
          <h4>Selecteer versie en configuratie versie</h4>
          <div class="select-model">
            <label class="ml-2">Model versie:</label>
            <Dropdown
              v-model="selectedPredictionModelVersion"
              :options="predictionModelVersions"
              option-label="description"
              option-value="name"
              class="my-1"
              :invalid="selectedPredictionModelVersion === null"
            />
            <label class="ml-3">{{ trainedVersionMessage }}</label>
            <label class="ml-2">Configuratie versie:</label>
            <Dropdown
              v-model="selectedPredictionModelConfigVersion"
              :options="predictionModelConfigVersions"
              option-label="description"
              option-value="name"
              class="my-1"
              :invalid="selectedPredictionModelConfigVersion === null"
            />
            <label class="ml-3">{{ trainedConfigVersionMessage }}</label>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            class="flex align-items-center justify-content-center m-2"
            label="Start berekening"
            icon="pi pi-play"
            :disabled="buttonAdviceText != null"
            @click="onStartCalculation"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Card>

    <Card>
      <template #title>
        Contingent: lopende berekeningen en resultaten
        <help-sidebar help-reference="Contingent: lopende/voltooide berekeningen" />
      </template>
      <template #content>
        <view-edit-select-table
          :rows="predictionJobs"
          :columns="predictionJobsColumns"
          :page-size="10"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :deletable-row="true"
          :custom-actions="jobActions"
          :loading="loadingPredictionJobs"
          @delete="onRemovePredictionJob"
        />
      </template>
    </Card>
    <view-training-details
      v-if="trainedModelToView!==null"
      :training-job="trainedModelToView"
      @close="trainedModelToView=null"
    />
    <view-prediction-details
      v-if="predictionResultToView!==null"
      :prediction-job="predictionJobToView"
      :prediction-job-result="predictionResultToView"
      @close="predictionResultToView=null"
    />
  </div>
  <Dialog
    v-model:visible="showDivergentVersionDialog" class="w-6" header="Bevestig afwijkende versie"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3 text-4xl" />
      <br>
      <span>Gekozen predictie model versie: <b>{{ selectedPredictionModelVersion }}</b></span>
      <br>
      <span>Model versie gebruikt voor getraind model: <b>{{
        selectedTrainingResult.training_setup.kfp_version
      }}</b></span>
      <br>
      <br>
      <span>Gekozen predictie configuratie versie: <b>{{ selectedPredictionModelConfigVersion }}</b></span>
      <br>
      <span>Configuratie versie gebruikt voor getraind model: <b>{{
        selectedTrainingResult.training_setup.kfp_config_version
      }}</b></span>
      <br><br>
      <span>Wilt U voorspellen met een andere versie?</span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" text @click="showDivergentVersionDialog = false" />
      <Button label="Ja" icon="pi pi-check" text class="p-button-danger" @click="startCalculation" />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showAlternativeVersionDialog" class="w-6" header="Bevestig niet-standaard versie"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3 text-4xl" />
      <br>
      <span>Het gekozen getraind model heeft een niet-standaard (verouderde) versie of configuratie versie.</span>
      <br><br>
      <span>Gekozen getraind model versie: <b>{{ selectedTrainingResult.training_setup.kfp_version }}</b></span>
      <br>
      <span>Standaard training versie: <b>{{ defaultTrainingVersion }}</b></span>
      <br><br>
      <span>Gekozen getraind model configuratie versie: <b>{{
        selectedTrainingResult.training_setup.kfp_config_version
      }}</b></span>
      <br>
      <span>Standaard training configuratie versie: <b>{{ defaultTrainingConfigVersion }}</b></span>
      <br><br>
      <span>Als U met de standaard versie wilt voorspellen moet U eerst een nieuw model trainen.</span>
      <br>
      <span>Wilt U doorgaan met voorspellen?</span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" text @click="showAlternativeVersionDialog = false" />
      <Button label="Ja" icon="pi pi-check" text class="p-button-danger" @click="startCalculation" />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showMapResultsWindow"
    modal
    class="w-full h-full p-4"
    header="Resultaten"
    :pt="{
      content: {class: 'flex flex-auto'},
      root: {class:'max-h-full'}}"
  >
    <map-results :contingent-id="contingentToViewId" class="flex-auto" />
  </Dialog>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import HelpSidebar from "@/components/help/HelpSidebar";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import CitySelect from "@/components/base-components/CitySelect";
import {deleteData, fetchData, fetchFile, postData} from "@/api";
import {exportFileFromBloburl} from "@/helpers/functions";
import ViewTrainingDetails from "@/components/training/ViewTrainingDetails";
import ViewPredictionDetails from "@/components/prediction/ViewPredictionDetails";
import {useKeycloak} from "@/keycloak/authentication";
import {DataTableSortOption, DataTableSortOrder} from "@/types";
import {errorToast, infoToast, successToast} from "@/toastService";
import MapResults from '@/views/MapResults.vue';
import {useKfpJobs} from "@/composables/kfpJobs";

const {
  getTrainingResults,
  getTrainingResultsQualities,
  getPredictionJobs,
  queryTrainingJobsResults,
  queryPredictionJobs,
} = useKfpJobs();
const trainingResults = getTrainingResults();
const trainingResultsQualities = getTrainingResultsQualities();
const predictionJobs = getPredictionJobs();
const {hasRole} = useKeycloak();

const predictionName = ref("");
const selectedTrainingResult = ref();
const loadingTrainedModels = ref(true);
const trainedModelToView = ref(null);
const defaultTrainingVersion = ref(null);
const defaultTrainingConfigVersion = ref(null);
const trainedModelColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);
const noPredctionModelsFound = ref(false);
const predictionModelMessage = ref("(Selecteer een getraind model om compatibele predictie modellen te zien...)");
const trainedVersionMessage = ref("(Selecteer een predictie model om model versies te zien...)");
const trainedConfigVersionMessage = ref("(Selecteer een predictie model om configuratie versies te zien...)");

const loadingPredictionJobs = ref(true);
const predictionJobToView = ref([]);
const predictionResultToView = ref(null);

const allModels = ref(null);
const allPredictionsModels = ref(null);
const predictionModels = ref([]);
const predictionModelVersions = ref([]);
const predictionModelConfigVersions = ref([]);
const selectedPredictionModelName = ref(null);
const selectedPredictionModelVersion = ref(null);
const selectedPredictionModelConfigVersion = ref(null);
const configVersionNames = ref(null);

const selectedArea = ref("city");
const selectedCity = ref(null);
// const selected_province = ref("");
const showDivergentVersionDialog = ref(false);
const showAlternativeVersionDialog = ref(false);
const showMapResultsWindow = ref(false);

const contingentToViewId = ref(null);

const predictionJobsColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
  {field: 'progress_fraction', header: 'Voortgang', format: "fraction_is_percentage"}
]);

onMounted(() => {
  queryKfpPipelines();
  queryTrainingJobsResults().then(() => loadingTrainedModels.value = false);
  queryPredictionModels();
  queryPredictionJobs().then(() => loadingPredictionJobs.value = false);
});

const queryKfpPipelines = async () => {
  allModels.value = await fetchData("kfp_pipeline/");
};

const queryPredictionModels = async () => {
  const resp = await fetchData("prediction/model");
  allPredictionsModels.value = resp.cluster_models;
  configVersionNames.value = resp.config_version_names;
};

const onSelectTrainedModel = () => {
  const trainingModel = allModels.value.find(
      pipeline => pipeline.pipeline_name === selectedTrainingResult.value.training_setup.kfp_name
  );

  if (trainingModel === undefined) {
    noPredctionModelsFound.value = true;
    predictionModelMessage.value = " Geselecteerd training algoritme niet beschikbaar," +
        " neem contact op met de beheerder.";
    return;
  }

  predictionModels.value = allPredictionsModels.value.filter(
      model => trainingModel.compatible_prediction_kfp_names.includes(model.pipeline_name)
  );
  if (predictionModels.value.length === 0) {
    noPredctionModelsFound.value = true;
    predictionModelMessage.value = " Geen voorspellings algoritme beschikbaar, neem contact op met de beheerder.";
    return;
  }

  noPredctionModelsFound.value = false;
  predictionModelMessage.value = " ('" + selectedTrainingResult.value.name + "' getraind met model '"
      + trainingModel.display_name + "')";
};

const onSelectPredictionModel = () => {
  const selectedPredictionModel = predictionModels.value.find(
      model => model.pipeline_name === selectedPredictionModelName.value
  );
  trainedVersionMessage.value = " ('" + selectedTrainingResult.value.name + "' getraind met model versie '"
      + selectedTrainingResult.value.training_setup.kfp_version + "')";
  trainedConfigVersionMessage.value = " ('" + selectedTrainingResult.value.name + "' getraind met configuratie versie '"
      + selectedTrainingResult.value.training_setup.kfp_config_version + "')";

  defaultTrainingVersion.value = selectedPredictionModel.kfp_version_default;
  defaultTrainingConfigVersion.value = selectedPredictionModel.kfp_config_version_default;

  selectedPredictionModelVersion.value = [];
  predictionModelVersions.value = [];
  for (let version of selectedPredictionModel.kfp_versions) {
    let description = version;
    if (selectedTrainingResult.value.training_setup.kfp_version === version) {
      description = version + " (trained)";
      selectedPredictionModelVersion.value = version;
    }
    predictionModelVersions.value.push({name: version, description: description});
  }

  predictionModelConfigVersions.value = [];
  for (let configVersion of configVersionNames.value) {
    let description = configVersion;
    if (selectedTrainingResult.value.training_setup.kfp_config_version === configVersion) {
      description = configVersion + " (trained)";
      selectedPredictionModelConfigVersion.value = configVersion;
    }
    predictionModelConfigVersions.value.push({name: configVersion, description: description});
  }
};

const buttonAdviceText = computed(() => {
  if (predictionName.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedTrainingResult.value) {
    return "Selecteer een getraind model";
  } else if (selectedArea.value === "city" && !selectedCity.value) {
    return "Selecteer een gebied";
  } else if (selectedPredictionModelName.value === null) {
    return "Selecteer een predictie model";
  } else if (selectedPredictionModelConfigVersion.value === null) {
    return "Selecteer een predictie configuratie versie";
  }
  return null;
});

const onStartCalculation = async () => {
  if (hasRole('admin')) {
    if (selectedTrainingResult.value.training_setup.kfp_version === selectedPredictionModelVersion.value &&
        selectedTrainingResult.value.training_setup.kfp_config_version === selectedPredictionModelConfigVersion.value) {
      await startCalculation();
    } else {
      showDivergentVersionDialog.value = true;
    }
  } else {
    if (selectedTrainingResult.value.training_setup.kfp_version === defaultTrainingVersion.value &&
        selectedTrainingResult.value.training_setup.kfp_config_version === defaultTrainingConfigVersion.value) {
      await startCalculation();
    } else {
      showAlternativeVersionDialog.value = true;
    }
  }
};

const startCalculation = async () => {
  showDivergentVersionDialog.value = false;
  showAlternativeVersionDialog.value = false;

  let configVersionName = selectedPredictionModelConfigVersion.value;
  let payload = {
    name: predictionName.value,
    training_result_id: selectedTrainingResult.value.training_result.id,
    kfp_name: selectedPredictionModelName.value,
    kfp_version: selectedPredictionModelVersion.value,
    kfp_config_version: configVersionName,
  };
  if (selectedCity.value) {
    payload['selected_city'] = selectedCity.value.name;
  }

  infoToast("Predictie aanmaken...");

  // create training setup in db
  const ps_post = await postData("prediction/setup", payload);
  const ps_resp = await ps_post.json();

  if (ps_resp === null) {
    return;
  }

  // create and start prediction job
  await postData("prediction/job", {
    prediction_setup_id: ps_resp.id,
  });

  successToast(
      "Contingent berekening '" + predictionName.value + "' toegevoegd",
      "Getraind model: '" + selectedTrainingResult.value.name
  );
  predictionName.value = '';
  await queryPredictionJobs();
};

const onRemovePredictionJob = async (predictionJobId) => {
  let jobName = null;
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      jobName = predictionJob.name;
    }
  }
  const del_resp = await deleteData("prediction/job/" + predictionJobId);
  await queryPredictionJobs();

  if (del_resp.ok) {
    successToast("Contingent '" + jobName + "' verwijderd");
  } else {
    errorToast("Contingent '" + jobName + "' kan niet verwijderd worden");
  }
};

const onDownloadList = async (predictionJobId) => {
  const predictionResult = getPredictionResult(predictionJobId);

  if (!predictionResult) {
    errorToast("'Download adressen' niet mogelijk: geen contingent gevonden voor deze voorspelling.");
    return;
  }

  const blobUrl = await fetchFile("prediction/contingent_csv/" + predictionResult.id);
  exportFileFromBloburl(blobUrl, `${predictionResult.name}.csv`);
};

/**
 * Show results of prediction job on map. This will only work if the prediction job is finished.
 * @param predictionJobId
 */
const onOpenResultsWindow = (predictionJobId) => {
  showMapResultsWindow.value = true;
  const contingentId = getPredictionResult(predictionJobId).id;
  if (contingentId) {
    contingentToViewId.value = contingentId;
  }
};

/**
 * Get the contingent id of a prediction job. This is used to download the results.
 * @param predictionJobId
 */
const getPredictionResult = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      if ('prediction_result' in predictionJob && predictionJob.prediction_result !== null) {
        return predictionJob.prediction_result;
      }
    }
  }
  return null;
};

/**
 * Get the training job id of a prediction job. This is used to download the results.
 * @param trainingJobId
 */
const onViewTrainingDetails = async (trainingJobId) => {
  trainedModelToView.value = await fetchData("training/job/" + trainingJobId);
};

const onViewPredictionDetails = async (predictionJobId) => {
  predictionJobToView.value = predictionJobs.value.find(j => j.id === predictionJobId)
  predictionResultToView.value = await fetchData("prediction/job_result/" + predictionJobId);
};

const jobSucceeded = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId && predictionJob.last_status !== null) {
      return predictionJob.last_status.toUpperCase() === 'SUCCEEDED';
    }
  }
  return false;
};

const trainedModelActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewTrainingDetails, tooltip: 'Bekijk details'
  }
];

const jobActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewPredictionDetails, tooltip: 'Bekijk details'
  },
  {
    id: 2, icon: 'pi-download', visible: jobSucceeded, handler: onDownloadList, tooltip: 'Download adressen'
  },
  {
    id: 3, icon: 'pi-map', visible: jobSucceeded, handler: onOpenResultsWindow, tooltip: 'Bekijk resultaten'
  }
];

</script>

<style scoped>

.select-model {
  display: grid;
  width: 800px;
  grid-template-columns: 200px 250px 600px;
  align-items: center;
  justify-content: space-between;
}
</style>
