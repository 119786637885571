<template>
  <div class="pb-2">
    <Card>
      <template #title>
        <div class="table-header flex  md:justify-content-between">
          <div>
            Renovatie Concepten
            <help-sidebar help-reference="Renovatieconcepten" />
          </div>
          <div class="table-header flex flex-column md:flex-row md:justify-content-between">
            <Button
              style="margin-left: auto; margin-right: 0;"
              label="Voeg een renovatie concept toe"
              @click="openAddRenovationConceptDialog"
            />
          </div>
        </div>
      </template>
      <template #content>
        <view-edit-select-table
          :rows="renovationConcepts"
          :columns="rc_columns"
          :editable-row="true"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :deletable-row="true"
          :custom-actions="renovationConceptTableActions"
          :loading="loadingRenovationConcepts"
          @delete="onDeleteRenovationConcept"
          @edit="openEditRenovationConceptDialog"
        />
      </template>
      <!--      <template #footer>-->
      <!--        <div class="table-header flex flex-column md:flex-row md:justify-content-between">-->
      <!--          <Button-->
      <!--            style="margin-left: auto; margin-right: 0;"-->
      <!--            label="Voeg een renovatie concept toe"-->
      <!--            @click="openAddRenovationConceptDialog"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </template>-->
    </Card>
    <add-edit-renovation-concept-dialog
      v-if="addEditRCDialogVisible"
      id="rc-dialog"
      :renovation-concept="editRC"
      help-reference="Voeg een renovatieconcept toe"
      @add="addRenovationConcept"
      @update="updateRenovationConcept"
      @cancel="closeRenovationConceptDialog"
    />
    <view-renovation-concept-details
      v-if="renovationConceptToView!==null"
      :renovation-concept="renovationConceptToView"
      @close="renovationConceptToView=null"
    >
      <div v-if="beforeDeleteCheck">
        <Message severity="warn" :closable="false">
          Na 'Bevestig' worden alle items hierboven ook verwijderd!
        </Message>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            label="Annuleer"
            icon="pi pi-times"
            class="p-button-text"
            @click="renovationConceptToView=null; beforeDeleteCheck=false;"
          />
          <Button
            label="Bevestig"
            icon="pi pi-check"
            autofocus
            @click="deleteRenovationConcept(renovationConceptToView.id, renovationConceptToView.name)"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">
            Renovatie Concept verwijderen?
          </div>
        </div>
      </div>
    </view-renovation-concept-details>
    <Card>
      <template #title>
        <div class="table-header flex  md:justify-content-between">
          <div>
            Track record
            <help-sidebar help-reference="Track records" />
          </div>
          <Button
            style="margin-left: auto; margin-right: 0;"
            label="Voeg een track record toe"
            @click="openAddLabelDataSetDialog"
          />
        </div>
      </template>
      <template #content>
        <view-edit-select-table
          :rows="labelDataSets"
          :columns="ld_columns"
          :editable-row="true"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :deletable-row="true"
          :custom-actions="labelDataSetTableActions"
          :loading="loadingTrackRecords"
          @delete="onDeleteLabelDataSet"
          @edit="openEditLabelDataSetDialog"
        />
      </template>
      <!--      <template #footer>-->
      <!--        <div class="table-header flex flex-column md:flex-row md:justify-content-between">-->
      <!--          <Button-->
      <!--            style="margin-left: auto; margin-right: 0;"-->
      <!--            label="Voeg een label data set toe"-->
      <!--            @click="openAddLabelDataSetDialog"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </template>-->
    </Card>
    <add-edit-label-data-set-dialog
      v-if="addEditLabelDataSetDialogVisible"
      :label-data-set="editingLabelDataSet"
      :renovation-concepts="renovationConcepts"
      help-reference="Voeg track record toe"
      @add="addLabelSet"
      @update="updateLabelDataSet"
      @cancel="closeLabelDataSetDialog"
    />
    <view-label-data-set-details
      v-if="labelDataSetToView!==null"
      :label-data-set="labelDataSetToView"
      @close="labelDataSetToView=null"
    >
      <div v-if="beforeDeleteCheck">
        <Message severity="warn" :closable="false">
          Na 'Bevestig' worden alle items hierboven ook verwijderd!
        </Message>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            label="Annuleer"
            icon="pi pi-times"
            class="p-button-text"
            @click="labelDataSetToView=null; beforeDeleteCheck=false;"
          />
          <Button
            label="Bevestig"
            icon="pi pi-check"
            autofocus
            @click="deleteLabelDataSet(labelDataSetToView.id, labelDataSetToView.name)"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">
            Track record verwijderen?
          </div>
        </div>
      </div>
    </view-label-data-set-details>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {deleteData, fetchData, postData, putData} from "@/api";
import HelpSidebar from "@/components/help/HelpSidebar"
import {buildingParts, DataTableSortOption, DataTableSortOrder, LabelDataSet, RenovationConcept} from "@/types";
import AddEditRenovationConceptDialog from "@/components/renovation_concept/AddEditRenovationConceptDialog";
import AddEditLabelDataSetDialog from "@/components/label_data_set/AddEditLabelDataSetDialog";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import ViewRenovationConceptDetails from "@/components/renovation_concept/ViewRenovationConceptDetails";
import ViewLabelDataSetDetails from "@/components/label_data_set/ViewLabelDataSetDetails";
import {getKeyByValue} from "@/helpers/functions";
import {clearToaster, errorToast, infoToast, successToast} from "@/toastService";

const rc_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const ld_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'nr_of_entries', header: 'Adressen'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const loadingRenovationConcepts = ref(true);
const loadingTrackRecords = ref(true);
const beforeDeleteCheck = ref(false);
const renovationConcepts = ref(null);
const renovationConceptToView = ref(null);
const editRC = ref(null);
const addEditRCDialogVisible = ref(false);
const currentlyBeingEditedRenovationConceptID = ref(null);

const queryRenovationConcepts = async () => {
  renovationConcepts.value = await fetchData("renovation_concept/");
};
queryRenovationConcepts().then(() => loadingRenovationConcepts.value = false);


const addRenovationConcept = async (concept) => {
  concept.renovation_measures = prepareRenovationMeasuresForServer(concept.renovation_measures);

  addEditRCDialogVisible.value = false;
  const resp = await postData("renovation_concept/", concept);
  let concept_result = await (resp.json());
  renovationConcepts.value.push(concept_result);
};

const updateRenovationConcept = async (concept) => {
  concept.renovation_measures = prepareRenovationMeasuresForServer(concept.renovation_measures);

  addEditRCDialogVisible.value = false;
  let concept_id = currentlyBeingEditedRenovationConceptID.value;

  const resp = await putData("renovation_concept/" + concept_id, concept);
  let concept_result = await (resp.json());

  renovationConcepts.value = renovationConcepts.value.map(rc => {
    if (rc.id === currentlyBeingEditedRenovationConceptID.value) {
      rc = concept_result;
    }
    return rc;
  });

  currentlyBeingEditedRenovationConceptID.value = null;
};

const onDeleteRenovationConcept = async (concept_id) => {
  const resp = await fetchData("renovation_concept/" + concept_id);
  if (resp.label_data_sets.length > 0 || resp.training_jobs.length > 0 || resp.prediction_jobs.length > 0) {
    renovationConceptToView.value = resp;
    beforeDeleteCheck.value = true;
  } else {
    await deleteRenovationConcept(concept_id, resp.name);
  }
};

const deleteRenovationConcept = async (concept_id, rcName) => {
  infoToast("Renovatieconcept verwijderen...");
  const resp = await deleteData("renovation_concept/" + concept_id);
  if (resp.ok) {
    renovationConcepts.value = renovationConcepts.value.filter(el => el.id !== concept_id);
  }
  await queryLabelDataSets();
  if (resp.ok) {
    clearToaster();
    successToast("Renovatieconcept '" + rcName + "' verwijderd");
  } else {
    errorToast("Renovatieconcept '" + rcName + "' kan niet verwijderd worden");
  }
  renovationConceptToView.value = null;
  beforeDeleteCheck.value = false;
};

const openAddRenovationConceptDialog = () => {
  editRC.value = null;
  addEditRCDialogVisible.value = true;
};

const openEditRenovationConceptDialog = async (concept_id) => {
  const renovationConcept = await fetchData("renovation_concept/" + concept_id);

  renovationConcept.renovation_measures = prepareRenovationMeasuresForDialog(renovationConcept.renovation_measures);

  currentlyBeingEditedRenovationConceptID.value = concept_id;
  editRC.value = new RenovationConcept(renovationConcept.name, renovationConcept.renovation_measures);
  addEditRCDialogVisible.value = true;
};

const closeRenovationConceptDialog = () => {
  addEditRCDialogVisible.value = false;
  currentlyBeingEditedRenovationConceptID.value = null;
};

const labelDataSets = ref([]);
const editingLabelDataSet = ref(null);
const addEditLabelDataSetDialogVisible = ref(false);
const editingLabelDataSetID = ref(null);
const labelDataSetToView = ref(null);

const queryLabelDataSets = async () => {
  labelDataSets.value = await fetchData("label_data_set/");
};
queryLabelDataSets().then(() => loadingTrackRecords.value = false);

const addLabelSet = async (labelDataSet) => {
  addEditLabelDataSetDialogVisible.value = false;
  infoToast("Track record toevoegen. Dit kan even duren...");
  const resp = await postData("label_data_set/", labelDataSet);
  successToast("Track record toegevoegd.");
  let label_data_set_result = await (resp.json());
  labelDataSets.value.push(label_data_set_result);
};

const updateLabelDataSet = async (label_data_set) => {
  addEditLabelDataSetDialogVisible.value = false;
  let label_data_set_id = editingLabelDataSetID.value;

  const resp = await putData("label_data_set/" + label_data_set_id, label_data_set);
  let label_data_set_result = await (resp.json());

  labelDataSets.value = labelDataSets.value.map(label_data_set => {
    if (label_data_set.id === editingLabelDataSetID.value) {
      label_data_set = label_data_set_result;
    }
    return label_data_set;
  });

  editingLabelDataSetID.value = null;
};


const onDeleteLabelDataSet = async (label_data_set_id) => {
  const resp = await fetchData("label_data_set/" + label_data_set_id);
  if (resp.training_jobs.length > 0 || resp.prediction_jobs.length > 0) {
    labelDataSetToView.value = resp;
    beforeDeleteCheck.value = true;
  } else {
    await deleteLabelDataSet(label_data_set_id, resp.name);
  }
};

const deleteLabelDataSet = async (label_data_set_id, setName) => {
  infoToast("Track record verwijderen...");
  const resp = await deleteData("label_data_set/" + label_data_set_id);
  if (resp.ok) {
    labelDataSets.value = labelDataSets.value.filter(el => el.id !== label_data_set_id);
  }
  if (resp.ok) {
    successToast("Getrained model '" + setName + "' verwijderd");
  } else {
    errorToast("Getrained model '" + setName + "' kan niet verwijderd worden");
  }
  labelDataSetToView.value = null;
  beforeDeleteCheck.value = false;
};

const openAddLabelDataSetDialog = () => {
  editingLabelDataSet.value = null;
  addEditLabelDataSetDialogVisible.value = true;
};

const openEditLabelDataSetDialog = async (label_data_set_id) => {
  const resp = await fetchData("label_data_set/" + label_data_set_id);
  editingLabelDataSetID.value = label_data_set_id;
  editingLabelDataSet.value = new LabelDataSet(
      resp.name,
      resp.label_data_entries,
      resp.renovation_concept_id,
      resp.renovation_concept
  );
  addEditLabelDataSetDialogVisible.value = true;
};

const closeLabelDataSetDialog = () => {
  addEditLabelDataSetDialogVisible.value = false;
  editingLabelDataSetID.value = null;
};

const onViewRenovationConceptDetails = async (renovationConceptId) => {
  renovationConceptToView.value = await fetchData("renovation_concept/" + renovationConceptId);
  console.log(renovationConceptToView.value);
};

const onViewLabelDataSetDetails = async (labelDataSetId) => {
  labelDataSetToView.value = await fetchData("label_data_set/" + labelDataSetId);
};

const prepareRenovationMeasuresForServer = (renovationMeasures) => {
  // Building parts on the website are in Dutch, but the backend server expects them in all caps English (see enums.py)
  return renovationMeasures.map(measure => {
    measure.building_part = getKeyByValue(buildingParts, measure.building_part);
    return measure;
  });
};

const prepareRenovationMeasuresForDialog = (renovationMeasures) => {
  // Building parts on the website are in Dutch, but the backend sends them in English. So, translate them.
  return renovationMeasures.map(measure => {
    measure.building_part = buildingParts[measure.building_part];
    return measure;
  });
};

const renovationConceptTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewRenovationConceptDetails,
    tooltip: 'Bekijk details'
  }
];

const labelDataSetTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewLabelDataSetDetails,
    tooltip: 'Bekijk details'
  }
];

</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color .2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}
</style>
