<template>
  <div class="grid p-2">
    <Card class="w-full mt-1">
      <template #title>
        <div class="table-header flex  md:justify-content-between">
          <div>
            Beheer kubeflow pipeline configuraties
            <help-sidebar help-reference="Beheer kubeflow pipeline configuraties" />
          </div>
          <div class="table-header flex flex-column md:flex-row md:justify-content-between">
            <Button
              label="Versie toevoegen"
              @click="addKfpConfigVersionDialogVisible=true;"
            />
          </div>
        </div>
      </template>
      <template #content>
        <view-edit-select-table
          :rows="kfpConfigVersions"
          :columns="kfpConfigVersionsColumns"
          :deletable-row="true"
          :loading="loadingKfpConfigVersions"
          :custom-actions="kfpConfigVersionsTableActions"
          @delete="onDeleteKfpConfigVersion"
        />
      </template>
    </Card>
  </div>
  <add-kfp-config-version-dialog
    v-if="addKfpConfigVersionDialogVisible"
    @add="addKfpConfigVersion"
    @cancel="addKfpConfigVersionDialogVisible=false;"
  />
  <view-kfp-config-version
    v-if="viewKfpConfigVersionDialogVisible"
    :kfp-config-version="kfpConfigVersionToView"
    @close="viewKfpConfigVersionDialogVisible=false;"
  />
</template>

<script setup>
import {onMounted, ref} from "vue";
import {deleteData, fetchData, postData} from "@/api";
import HelpSidebar from "@/components/help/HelpSidebar.vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable.vue";
import AddKfpConfigVersionDialog from "@/components/settings/AddKfpConfigVersionDialog.vue";
import {clearToaster, errorToast, successToast} from "@/toastService";
import ViewKfpConfigVersion from "@/components/settings/ViewKfpConfigVersion.vue";

const kfpConfigVersions = ref([]);
const addKfpConfigVersionDialogVisible = ref(false);
const viewKfpConfigVersionDialogVisible = ref(false);
const kfpConfigVersionToView = ref(null);
const loadingKfpConfigVersions = ref(true);

const kfpConfigVersionsColumns = ref([
  {field: 'version', header: 'Versie naam'},
  {field: 'training_config_file_name', header: 'Training configuratie file naam'},
  {field: 'prediction_config_file_name', header: 'Predictie configuratie file naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
]);

onMounted(() => {
  queryKfpConfigVersions().then(() => loadingKfpConfigVersions.value = false);
});

const queryKfpConfigVersions = async () => {
  kfpConfigVersions.value = await fetchData("kfp_config_version/");
  for (const configVersion of kfpConfigVersions.value) {
    configVersion["name"] = configVersion["version"];
  }
};

const addKfpConfigVersion = async (kfpConfigVersion) => {
  const resp = await postData('kfp_config_version/', kfpConfigVersion);
  if (!resp.ok) {
    errorToast("Kfp configuratie versie '" + kfpConfigVersion.version + "' kan niet toegevoegd worden");
    return;
  }

  await queryKfpConfigVersions();
  clearToaster();
  successToast("Kfp configuratie versie '" + kfpConfigVersion.version + "' toegevoegd");

  addKfpConfigVersionDialogVisible.value = false;
};

const onDeleteKfpConfigVersion = async (kfpConfigVersionId) => {
  const configVersion = kfpConfigVersions.value.find((el) => el.id === kfpConfigVersionId);
  const resp = await deleteData("kfp_config_version/" + kfpConfigVersionId);
  if (!resp.ok) {
    errorToast("Kfp configuratie versie '" + configVersion.version + "' kan niet verwijderd worden");
    return;
  }
  await queryKfpConfigVersions();
  clearToaster();
  successToast("Kfp configuratie versie '" + configVersion.version + "' verwijderd");
};

const onViewKfpConfigVersion = (kfpConfigVersionId) => {
  kfpConfigVersionToView.value = kfpConfigVersions.value.find((el) => el.id === kfpConfigVersionId);
  viewKfpConfigVersionDialogVisible.value = true;
};

const kfpConfigVersionsTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewKfpConfigVersion,
    tooltip: 'Bekijk details'
  }
];
</script>