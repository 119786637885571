<template>
  <div class="grid p-2">
    <Card class="w-full mt-1">
      <template #title>
        Beheer reken modellen (pipelines): modus, naam en standaard versies
        <help-sidebar help-reference="Beheer reken modellen (pipelines): modus, naam en standaard versies" />
      </template>
      <template #content>
        <view-edit-select-table
          :columns="kfpPipelinesColumns"
          :rows="kfpPipelines"
          :editable-row="true"
          :loading="loadingKfpPipelines"
          @edit="openEditKfpPipelineDialog"
        />
        <edit-kfp-pipeline-dialog
          v-if="editKfpPipelineDialogVisible"
          :kfp-pipeline-info="kfpPipelineToEdit"
          :kfp-config-versions="kfpConfigVersionNames"
          :kfp-pipeline-names="kfpPipelineNames"
          help-reference="Update KFP pipeline settings"
          @update="updateKfpPipeline"
          @cancel="closeEditKfpPipelineDialog"
        />
      </template>
      <template #footer />
    </Card>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {fetchData, postData} from "@/api";
import HelpSidebar from "@/components/help/HelpSidebar.vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable.vue";
import EditKfpPipelineDialog from "@/components/settings/EditKfpPipelineDialog.vue";
import {kfpUiModes} from "@/types";

const kfpPipelines = ref([]);
const loadingKfpPipelines = ref(true);
const editKfpPipelineDialogVisible = ref(false);
const kfpPipelineToEdit = ref(null);
const kfpConfigVersionNames = ref([]);
const kfpPipelineNames = ref([]);

onMounted(() => {
  queryKfpPipelines().then(() => loadingKfpPipelines.value = false);
});

const queryKfpPipelines = async () => {
  kfpPipelines.value = await fetchData("kfp_pipeline/");
  for (const pipeline of kfpPipelines.value) {
    pipeline.id = pipeline.pipeline_name;
    pipeline.ui_mode = kfpUiModes[pipeline.ui_mode];
    kfpPipelineNames.value.push(pipeline.pipeline_name);
  }
};

const kfpPipelinesColumns = ref([
  {field: 'pipeline_name', header: 'Pipeline naam'},
  {field: 'ui_mode', header: 'UI mode'},
  {field: 'display_name', header: 'Weergave naam'},
  {field: 'kfp_version_default', header: 'Standaard versie'},
  {field: 'kfp_config_version_default', header: 'Standaard config versie'},
  {field: 'compatible_prediction_kfp_names', header: 'Compatibele predictie kfp namen'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
]);

const openEditKfpPipelineDialog = async (pipelineName) => {
  kfpPipelineToEdit.value = await fetchData("kfp_pipeline/" + pipelineName);
  const kfpConfigVersions = await fetchData("kfp_config_version/");
  kfpConfigVersionNames.value = [];
  for (const configVersion of kfpConfigVersions) {
    kfpConfigVersionNames.value.push(configVersion["version"]);
  }
  editKfpPipelineDialogVisible.value = true;
};

const updateKfpPipeline = async (kfpPipeline) => {
  const resp = await postData("kfp_pipeline/", kfpPipeline);
  const updatedPipeline = await resp.json();

  kfpPipelines.value = kfpPipelines.value.map(pipeline => {
    if (pipeline.id === updatedPipeline.pipeline_name) {
      pipeline = updatedPipeline;
      pipeline.ui_mode = kfpUiModes[pipeline.ui_mode];
      pipeline.id = pipeline.pipeline_name;
    }
    return pipeline;
  });
  editKfpPipelineDialogVisible.value = false;
};

const closeEditKfpPipelineDialog = () => {
  editKfpPipelineDialogVisible.value = false;
};

</script>