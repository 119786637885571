<template>
  <Card>
    <template #title> Manage Users</template>
    <template #content>
      <Button :disabled="!canManageUsers" label="Create" icon="pi pi-plus" @click="createUser" />
      <Message v-if="!canManageUsers" severity="warn" :closable="false">
        You do not have the rights to create or edit
        users on this page.
      </Message>

      <DataTable :value="users">
        <Column field="id" header="ID" />
        <Column field="username" header="Username" />
        <Column field="firstname" header="First name" />
        <Column field="lastname" header="Last name" />
        <Column field="email" header="Email" />
        <Column header="Organization">
          <template #body="slotProps">
            {{ slotProps.data.organization.name }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil" :disabled="!canManageUsers || slotProps.data.username == null"
              @click="editUser(slotProps.data)"
            />
            <Button
              icon="pi pi-trash" :disabled="!canManageUsers || slotProps.data.username == null"
              @click="deleteUser(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>

      <Dialog v-model:visible="displayDialog" header="User Details" modal :closable="false" width="600">
        <div class="flex flex-column gap-3">
          <div class="flex flex-column gap-1">
            <label for="username">Username</label>
            <InputText id="username" v-model="editingUser.username" :disabled="editingUser.id != null" />
          </div>
          <div class="flex flex-column gap-1">
            <label for="password">Password</label>
            <Password id="password" v-model="editingUser.password" toggle-mask />
          </div>
          <div class="flex flex-column gap-1">
            <label for="name">First name</label>
            <InputText id="firstname" v-model="editingUser.firstname" />
          </div>
          <div class="flex flex-column gap-1">
            <label for="name">Last name</label>
            <InputText id="lastname" v-model="editingUser.lastname" />
          </div>
          <div class="flex flex-column gap-1">
            <label for="name">Email</label>
            <InputText id="email" v-model="editingUser.email" />
          </div>
          <div class="flex flex-column gap-1">
            <label for="organization">Organization</label>
            <Dropdown
              id="organization" v-model="editingUser.organization_id" :options="organizations"
              option-label="name" option-value="id"
            />
          </div>
          <div>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="cancel" />
            <Button label="Save" icon="pi pi-check" @click="saveUser" />
          </div>
        </div>
      </Dialog>
    </template>
  </Card>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import {deleteData, fetchData, postData, putData} from "@/api";
import {loadOrganizations} from "@/service/OrganizationService";
import Dropdown from "primevue/dropdown";
import {checkRoleAuthentication} from "@/keycloak/authentication";
import {errorToast} from "@/toastService";

const displayDialog = ref(false);
const editingUser = ref({id: '', username: '', firstname: '', lastname: '', email: '', organization_id: ''});
const users = ref([]);
const organizations = ref([]);

const canManageUsers = checkRoleAuthentication('manage-users', 'master-realm');

onMounted(async () => {
  organizations.value = await loadOrganizations();
  await loadUsers();
});

/**
 * Load users from API.
 * @returns {Promise<void>}
 */
const loadUsers = async () => {
  users.value = await fetchData('auth/users/');
};

/**
 * Save user to API. Either post or put depending if it's a new user or an existing one being edited.
 * @returns {Promise<void>}
 */
const saveUser = async () => {
  let response;
  if (editingUser.value.id) {
    response = await putData('auth/users/' + editingUser.value.id, editingUser.value);
  } else {
    response = await postData('auth/users/', editingUser.value);
  }
  if (response.ok) {
    displayDialog.value = false;
  } else {
    errorToast("Fout opgetreden", "Er is een fout opgetreden tijdens het opslaan van de gebruiker" + response.statusText);
  }
  await loadUsers();
};

const deleteUser = async (user) => {
  // Implement fetch call here to delete user
  const response = await deleteData('auth/users/' + user.id);
  if (response.ok) {
    displayDialog.value = false;
  } else {
    const respJson = await response.json();
    errorToast(
        "Fout opgetreden", "Er is een fout opgetreden tijdens het verwijderen van de gebruiker\n"
        + respJson.message
    );
  }
  await loadUsers();
};

const editUser = (user) => {
  editingUser.value.id = user.id;
  editingUser.value.username = user.username;
  editingUser.value.organization_id = user.organization_id;
  editingUser.value.firstname = user.firstname;
  editingUser.value.lastname = user.lastname;
  editingUser.value.email = user.email;
  displayDialog.value = true;
};

/**
 * Create a new user.
 */
const createUser = () => {
  editingUser.value.id = null;
  editingUser.value.username = '';
  editingUser.value.organization_id = '';
  editingUser.value.firstname = '';
  editingUser.value.lastname = '';
  editingUser.value.email = '';
  displayDialog.value = true;
};

const cancel = () => {
  displayDialog.value = false;
};
</script>
