<template>
  <div class="card">
    <!-- Dialog width is 95% of the screen width, but at most 100rem -->
    <Dialog
      :visible="true"
      :modal="true"
      :closable="false"
      :style="{ width: '100rem' }"
      :breakpoints="{ '1920px': '95vw' }"
    >
      <template #header>
        <h3>
          '{{ kfpPipeline.pipeline_name }}': beheer reken model modus, naam en standaard versie
          <help-sidebar
            v-if="helpReference"
            :help-reference="helpReference"
          />
        </h3>
      </template>

      <div class="field grid">
        <label for="pipeline_name" class="col-12 mb-2 md:col-3 md:mb-0">KFP pipeline naam:</label>
        <label
          id="pipeline_name"
          class="col-12 md:col-3"
        >{{ kfpPipeline.pipeline_name }}</label>
      </div>
      <div class="field grid">
        <label for="ui_mode" class="col-12 mb-2 md:col-3 md:mb-0">UI modus:</label>
        <Dropdown
          id="ui_mode"
          v-model="uiMode"
          :options="uiModeOptions"
          option-label="label"
          option-value="value"
          :placeholder="uiMode"
          class="col-12 md:col-3"
        />
      </div>
      <div class="field grid">
        <label
          for="display_name"
          class="col-12 mb-2 md:col-3 md:mb-0"
          :style="{ color: !uiMode.toLowerCase().includes('not_used') ? '' : 'lightgrey'}"
        >Weergave naam:</label>
        <input
          id="display_name"
          v-model="displayName"
          type="text"
          class="col-12 md:col-3 text-base text-color surface-overlay p-2 border-1 border-solid surface-border
                 border-round appearance-none outline-none focus:border-primary"
          :disabled="uiMode.toLowerCase().includes('not_used')"
        >
      </div>
      <div class="field grid" :class="{ active: false}">
        <label
          for="kfp_version_default"
          class="col-12 mb-2 md:col-3 md:mb-0"
          :style="{ color: !uiMode.toLowerCase().includes('not_used') ? '' : 'lightgrey'}"
        >Standaard versie:</label>
        <Dropdown
          id="kfp_version_default"
          v-model="kfpVersionDefault"
          :options="kfpVersionOptions"
          option-label="label"
          option-value="value"
          :placeholder="kfpVersionDefault"
          class="col-12 md:col-3"
        />
      </div>
      <div class="field grid" :class="{ active: false}">
        <label
          for="kfp_config_version_default"
          class="col-12 mb-2 md:col-3 md:mb-0"
          :style="{ color: !uiMode.toLowerCase().includes('not_used') ? '' : 'lightgrey'}"
        >Standaard configuratie
          versie:</label>
        <Dropdown
          id="kfp_config_version_default"
          v-model="kfpConfigVersionDefault"
          :options="kfpConfigVersionOptions"
          option-label="label"
          option-value="value"
          :placeholder="kfpConfigVersionDefault"
          class="col-12 md:col-3"
        />
      </div>
      <div class="field grid">
        <label
          for="compatible_prediction_kfp_names" class="col-12 mb-2 md:col-3 md:mb-0"
          :style="{ color: uiMode.toLowerCase().includes('training') ? '' : 'lightgrey'}"
        >
          Compatibele predictie kfp pipelines:
        </label>
        <MultiSelect
          id="compatible_prediction_kfp_names"
          v-model="compatiblePredictionKfpNames"
          :options="compatiblePredictionKfpNamesOptions"
          option-label="label"
          option-value="value"
          class="col-12 md:col-3"
          :disabled="!uiMode.toLowerCase().includes('training')"
        />
      </div>

      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button label="Annuleer" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
          <Button
            label="Bevestig" icon="pi pi-check" autofocus :disabled="buttonAdviceText != null"
            @click="UpdateKfpPipeline"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted, computed, watch} from "vue";
import HelpSidebar from "@/components/help/HelpSidebar"
import {kfpUiModes, KfpPipelineInfo} from "@/types";

const props = defineProps({
  kfpPipelineInfo: {
    type: Object,
    required: false,
    default: null,
  },
  kfpPipelineNames: {
    type: Object,
    required: false,
    default: null,
  },
  kfpConfigVersions: {
    type: Object,
    required: false,
    default: null,
  },
  helpReference: {
    type: String,
    required: false,
    default: null
  }
});

const kfpPipeline = ref(props.kfpPipelineInfo);
const kfpConfigVersions = ref(props.kfpConfigVersions);
const kfpPipelineNames = ref(props.kfpPipelineNames);

const uiMode = ref(null);
const uiModeOptions = ref([]);
const displayName = ref(null);
const kfpVersionDefault = ref(null);
const kfpVersionOptions = ref([]);
const kfpConfigVersionDefault = ref(null);
const kfpConfigVersionOptions = ref([]);
const compatiblePredictionKfpNames = ref([]);
const compatiblePredictionKfpNamesOptions = ref([]);

const emit = defineEmits(["update", "cancel"]);

onMounted(() => {
  for (const ui_mode in kfpUiModes) {
    uiModeOptions.value.push({label: kfpUiModes[ui_mode], value: ui_mode});
  }
  for (const version of kfpPipeline.value.kfp_versions) {
    kfpVersionOptions.value.push({label: version, value: version});
  }
  for (const configVersion of kfpConfigVersions.value) {
    kfpConfigVersionOptions.value.push({label: configVersion, value: configVersion});
  }
  for (const pipelineName of kfpPipelineNames.value) {
    compatiblePredictionKfpNamesOptions.value.push({label: pipelineName, value: pipelineName});
  }

  uiMode.value = kfpPipeline.value.ui_mode;
  displayName.value = kfpPipeline.value.display_name;
  kfpVersionDefault.value = kfpPipeline.value.kfp_version_default;
  kfpConfigVersionDefault.value = kfpPipeline.value.kfp_config_version_default;
  if (kfpPipeline.value.compatible_prediction_kfp_names !== null) {
    compatiblePredictionKfpNames.value = kfpPipeline.value.compatible_prediction_kfp_names;
  }

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') emit('cancel');
  });
});

watch(uiMode, (selectedUiMode) => {
  if (selectedUiMode.toLowerCase().includes('not_used')) {
    displayName.value = "";
    kfpVersionDefault.value = null;
    kfpConfigVersionDefault.value = null;
  }
  if (!selectedUiMode.toLowerCase().includes('training')) {
    compatiblePredictionKfpNames.value = [];
  }
});

const hideDialog = () => {
  emit("cancel");
};

const UpdateKfpPipeline = () => {
  emit("update", new KfpPipelineInfo(
      kfpPipeline.value.pipeline_name,
      displayName.value,
      kfpVersionDefault.value,
      kfpConfigVersionDefault.value,
      compatiblePredictionKfpNames.value,
      uiMode.value,
  ));
};

const buttonAdviceText = computed(() => {
  if (uiMode.value.toLowerCase().includes('not_used')) {
    return null;
  }
  if (displayName.value === null || displayName.value.replace(/\s/g, '') === '') {
    return "Vul weergave naam in";
  } else if (kfpVersionDefault.value === null) {
    return "Kies een standaard versie";
  } else if (kfpConfigVersionDefault.value === null) {
    return "Kies een standaard configuratie versie";
  } else if (uiMode.value.toLowerCase().includes('training') && compatiblePredictionKfpNames.value.length === 0) {
    return "Kies één of meerdere compatibele predictie pipelines";
  }
  return null;
});

</script>

<style scoped>

</style>