import {ref} from "vue";
import {fetchData} from "@/api";
import {toast} from "@/toastService";
import {formatDateTime, getTrainingQualities} from "@/helpers/functions";

const trainingJobs = ref([]);
const trainingJobsQualities = ref(null);
const trainingResults = ref([]);
const trainingResultsQualities = ref(null);
const predictionJobs = ref([]);

export function useKfpJobs() {
    const getTrainingJobs = () => trainingJobs;
    const getTrainingJobsQualities = () => trainingJobsQualities;
    const getTrainingResults = () => trainingResults;
    const getTrainingResultsQualities = () => trainingResultsQualities;
    const getPredictionJobs = () => predictionJobs;

    const queryTrainingJobsResults = async (showToastsOnUpdates = true) => {
        const updatedJobs = await fetchData("training/job");
        if (showToastsOnUpdates) {
            showToastsFromUpdatedJobs(trainingJobs.value, updatedJobs, "Training");
        }
        trainingJobs.value = updatedJobs;
        trainingJobsQualities.value = getTrainingQualities(trainingJobs.value);

        // Only show training results within quality limit
        trainingResults.value = updatedJobs.filter((job) => {
            return job.training_result?.metrics?.accuracy_class > 0;
        });
        trainingResultsQualities.value = getTrainingQualities(trainingResults.value);
    };

    const queryPredictionJobs = async (showToastsOnUpdates = true) => {
        const updatedJobs = await fetchData("prediction/job");
        if (showToastsOnUpdates) {
            showToastsFromUpdatedJobs(predictionJobs.value, updatedJobs, "Predictie");
        }
        predictionJobs.value = updatedJobs;
    };

    return {
        getTrainingJobs,
        getTrainingJobsQualities,
        getTrainingResults,
        getTrainingResultsQualities,
        getPredictionJobs,
        queryTrainingJobsResults,
        queryPredictionJobs
    };
}

const jobUpdated = (job1, job2) => {
    // Only send toast when status is updated (not for every progress update)
    const keysToCheck = ["last_status"];
    for (const key of keysToCheck) {
        if (job1[key] !== job2[key]) {
            return true;
        }
    }
    return false;
};

const showToastsFromUpdatedJobs = (currentJobs, updatedJobs, jobTypeName) => {
    const severity_map = {
        'Succeeded': 'success',
        'Failed': 'error'
    };

    for (const updatedJob of updatedJobs) {
        const currentJob = currentJobs.find(job => job.id === updatedJob.id);
        if (!currentJob || !jobUpdated(currentJob, updatedJob)) {
            continue;
        }

        const severity = updatedJob.last_status in severity_map ? severity_map[updatedJob.last_status] : 'warn'
        // Create detail message for toast
        const message = "Aangemaakt: " + formatDateTime(updatedJob.created_at);
        toast(
            severity,
            `${jobTypeName} job '${updatedJob.name}' status: ${updatedJob.last_status}`,
            message
        );
    }
};
