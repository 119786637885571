<template>
  <div class="card">
    <!-- Dialog width is 95% of the screen width, but at most 100rem -->
    <Dialog
      :visible="true"
      :modal="true"
      :closable="false"
      :style="{ width: '100rem' }"
      :breakpoints="{ '1920px': '95vw' }"
    >
      <template #header>
        <h3>
          <span>Voeg een kubeflow pipelines configuratie versie toe</span>
        </h3>
      </template>

      <div class="grid pt-2 row-gap-3">
        <label for="naam" class="col-4">Naam:</label>
        <InputText
          id="naam"
          v-model="name"
          type="text"
          class="col-2"
          :invalid="name === ''"
        />
        <div class="col-6" />
        <label for="training-model-versie" class="align-items-center col-4">
          Selecteer training configuratie toml file:
        </label>
        <FileUpload
          mode="basic"
          accept=".toml"
          :max-file-size="10000000"
          label="ImportToml"
          choose-label="Selecteer"
          class="col"
          :auto="true"
          :custom-upload="true"
          @select="onUploadTrainingConfigFile"
        />
        <label class="align-items-center col-4" :class="{ 'text-red-500': trainingConfigTomlStr === null}">{{
          trainingConfigFileName
        }}</label>

        <label for="training-model-versie" class="align-items-center col-4 pt-2">
          Selecteer predictie configuratie toml file:
        </label>
        <FileUpload
          mode="basic"
          accept=".toml"
          :max-file-size="10000000"
          label="ImportToml"
          choose-label="Selecteer"
          class="col"
          :auto="true"
          :custom-upload="true"
          @select="onUploadPredictionConfigFile"
        />
        <label class="align-items-center col-4" :class="{ 'text-red-500': predictionConfigTomlStr === null}">{{
          predictionConfigFileName
        }}</label>
      </div>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button label="Annuleer" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
          <Button
            label="Bevestig" icon="pi pi-check" autofocus :disabled="buttonAdviceText != null"
            @click="addKfpConfigVersion"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import {ref, defineEmits, onMounted, computed} from "vue";
import {KfpConfigVersion} from "@/types";
import toml from "toml";
import {errorToast} from "@/toastService";

const name = ref("");
const trainingConfigFileName = ref("Geen file geselecteerd");
const trainingConfigTomlStr = ref(null);
const predictionConfigFileName = ref("Geen file geselecteerd");
const predictionConfigTomlStr = ref(null);

const emit = defineEmits(['add', 'cancel']);

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') emit('cancel');
  });
});


const hideDialog = () => {
  emit("cancel");
};

const onUploadConfigFile = (event, config_file_name_ref, training_config_toml_str_ref) => {
  const file = event.files[0]; // Get the first (and only) uploaded file
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        // check toml file
        toml.parse(e.target.result);
        training_config_toml_str_ref.value = e.target.result;
      } catch (error) {
        errorToast(`Error parsing TOML file '${file.name}'`, error);
      }
    };
    reader.readAsText(file);
    config_file_name_ref.value = file.name;
  }
};

const onUploadTrainingConfigFile = (event) => {
  onUploadConfigFile(event, trainingConfigFileName, trainingConfigTomlStr);
};

const onUploadPredictionConfigFile = (event) => {
  onUploadConfigFile(event, predictionConfigFileName, predictionConfigTomlStr);
};

const addKfpConfigVersion = () => {
  emit("add",
      new KfpConfigVersion(
          name.value,
          trainingConfigFileName.value,
          trainingConfigTomlStr.value,
          predictionConfigFileName.value,
          predictionConfigTomlStr.value,
      )
  );
};

const buttonAdviceText = computed(() => {
  if (name.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (trainingConfigTomlStr.value === null) {
    return "Voeg training configuratie toe";
  } else if (predictionConfigTomlStr.value === null) {
    return "Voeg predictie configuratie toe";
  }
  return null;
});

</script>

<style scoped>

</style>