<template>
  <Button
    icon="pi pi-info-circle"
    class="p-button-text p-button-rounded"
    @click="barVisible = true"
  />
  <Sidebar v-model:visible="barVisible" position="right" class="p-sidebar-md" @hide="hideSideBar">
    <h1>{{ helpTexts[helpReference].title }}</h1>
    <span
      v-for="section in helpTexts[helpReference].sections"
      :key="section"
    >
      <h2 v-if="section.title">{{ section.title }}</h2>
      <div
        v-for="paragraph in section.paragraphs"
        :key="paragraph"
        class="help-paragraph"
      >
        {{ paragraph }}
      </div>
    </span>
  </Sidebar>
</template>

<script setup>
import {ref, defineProps} from "vue";
import helpTexts from './help_texts.json';

defineProps({
  helpReference: String
});
const barVisible = ref(false);

const hideSideBar = () => {
  barVisible.value = false;
};

</script>

<style>

.help-paragraph {
  margin-top: 10px;
}

</style>